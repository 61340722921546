declare const RASA_PUBLIC_SERVICE_API: string

export const SESSION_STORAGE_KEY = 'RASA_SESSION'

export const getPublicServerUrl = (): string => {
  return `${RASA_PUBLIC_SERVICE_API}`
}

export const RASA_HOME_URL: string = 'http://www.rasa.io'
export const RASA_PRESS_URL: string =  RASA_HOME_URL + '/news'
export const RASA_PRIVACY_URL: string = RASA_HOME_URL + '/privacy'
export const RASA_HELP_BASE_URL: string = 'https://help.rasa.io'
export const RASA_HELP_HUBSPOT_DOCUMENTATION = RASA_HELP_BASE_URL + '/hubspot-integration-documentation'
export const RASA_HELP_INTEGRATION_WORK = RASA_HELP_BASE_URL + '/how-do-rasa.io-integrations-work'
export const RASA_HELP_EMBED_TOP_ARTICLE = RASA_HELP_BASE_URL + '/how-to-embed-your-top-articles-page-on-your-own-website'
export const RASA_HELP_ANTI_SPAM_EMAIL_DELIVERABILITY = RASA_HELP_BASE_URL + '/anti-spam-email-requirements-and-deliverability-considerations'
export const RASA_HELP_OVERVIEW_OF_DOMAIN_AUTH = RASA_HELP_BASE_URL + '/overview-of-domain-authentication'
export const RASA_HELP_HOW_TO_AUTH_DOMAIN = RASA_HELP_BASE_URL + '/how-to-authenticate-your-domain'
export const RASA_HELP_FIND_RSS_FEED = RASA_HELP_BASE_URL + '/finding-an-rss-feed'
export const RASA_HELP_SUBSCRIBER_PERMISSION = RASA_HELP_BASE_URL + '/guidelines-for-subscriber-permission'
export const RASA_HELP_DMARC = RASA_HELP_BASE_URL + '/dmarc-for-enhanced-email-security'
export const SUPPORT_EMAIL = 'support@rasa.io'
export const SUPPORT_MAIL_TO = `mailto:${SUPPORT_EMAIL}`
export const RASA_HELP_ADD_GOOGLE_NEWS = RASA_HELP_BASE_URL + '/how-to-add-a-google-news-feed-as-a-source'

export const STATUS_CODES = {
  UNPROCESSABLE: 422
}
