import { Loading } from 'components/loading'
import { RasaContext } from 'context'
import * as GenericRedux from 'generic/genericRedux'
import { RasaReactComponent } from 'generic/rasaReactComponent'
import React from 'react'
import * as Redux from 'redux'
import { SharedKeys, SharedStore } from 'shared/data-layer/sharedStore'
import { injectReducer } from 'store/index'
import { HeaderSettings } from '../headerSettings'
import { PreviewWidgetMenuComponent } from '../previewMenuWidget'
import '../styles.css'
import { MenuSection } from '../types'
import { COMPANY_NAME, MenuItems, MenuSections, REDUX_STORE_HOME, SignupLayoutAttributes, } from './constants'
import { SignupSettings } from './layouts/signupSettings'
import { PreviewComponent } from './preview'
import './style.scss'
import * as Types from './types'
import { PrivacySettings } from './layouts/privacySettings'
import { getEnvironmentSuffix } from '../../../generic/utility'
import { newsletterWhitelistDomains, WhitelistDomains } from '../../../whitelist-domain/utils'
import { FormLinkComponent } from '../utils'

type WidgetsSignupComponentProps = GenericRedux.AllComponentProps<any>
interface WidgetsSignupComponentState {
  communityId: string,
  communityRecordId: string,
  communityGuid: string,
  signup: Types.SignupComponentState,
  context?: any,
  selected: MenuSection,
  newsletterDomains: WhitelistDomains,
}

class WidgetsSignupComponent extends RasaReactComponent<WidgetsSignupComponentProps,
      WidgetsSignupComponentState> {
  public static contextType = RasaContext
  private sharedStore: SharedStore

  constructor(props: WidgetsSignupComponentProps) {
    super(props, 'widgetsLayout', {
      communityId: '',
      communityRecordId: '',
      communityGuid: '',
      selected: null,
      signup: {
        isLoading: true,
        configurations: null,
      },
      newsletterDomains: {},
    })
  }

  public componentDidMount = () => {
    this.sharedStore = SharedStore.instance(this.context)
    Promise.all([
      this.sharedStore.getValue(SharedKeys.activeCommunity),
      this.sharedStore.getValue(SharedKeys.hash),
      this.sharedStore.getValue(SharedKeys.person)
    ])
    .then(([activeCommunity, hash, person]) => {
      this.loadRecord(activeCommunity.communityId, activeCommunity.data.community_id)
      .then(() => {
        this.updateComponentState({
          privacy_lead_text: this.props.data.privacy_lead_text.replace(COMPANY_NAME, activeCommunity.data.community_name)
        })
        this.setState({
          communityId: activeCommunity.communityId,
          communityRecordId: activeCommunity.data.community_id,
          communityGuid: activeCommunity.data.community_guid,
          signup: {
            isLoading: false,
            configurations: this.props.data,
          },
        })
        newsletterWhitelistDomains((person.accountInfo || {}).account_guid, activeCommunity.communityId)
          .then((newsletterDomains: WhitelistDomains) => {
            this.setState({
              newsletterDomains,
            })
          })
      })
    })
  }

  public render() {
    return (
      <div className="sign-up-design">
      {this.state.isLoading ? <Loading size="64" /> :
    <div>
      <div className="widgets-tools-forms">
      <FormLinkComponent
        name="Sign-up form"
        description={['Use this page to promote subscriptions to your newsletter. Anyone who opts in on this form will be added to your contacts.']}
        link={this.signupUrl()}
        isRecommended={false} />
      </div>
      <div className="sign-up-container">
        <div className="preview">
          <div className="sign-up-body"
               style={{backgroundColor: '#FFFFFF', margin: '0px'}}>
              <PreviewComponent configurations={this.state.signup.configurations} data={this.props.data} />
          </div>
        </div>
        <div className="settings">
          <div className="designbuttonwrap">
            <div className={this.buttonClassNames(this.isDirty)}
                  onClick={() => this.saveRecord(this.state.communityRecordId)}>
              Save
            </div>
          </div>
          <div className="editor">
            {this.getEditor()}
          </div>
        </div>
        <div className="menu">
          <PreviewWidgetMenuComponent selectItem={this.selectItem}
            saveRecord={this.saveRecord} isDirty={this.isDirty} menuSections={MenuSections}>
          </PreviewWidgetMenuComponent>
        </div>
      </div>
     </div>
      }
    </div>
    )
  }

  private signupUrl = () => {
    return `${this.pagesWebsite()}/signup/${this.state.communityGuid}?utm_source=signup`
  }

  private pagesWebsite = (): string => {
    if (this.state.newsletterDomains.pages && this.state.newsletterDomains.pages.alternate_domain) {
      return `https://${this.state.newsletterDomains.pages.alternate_domain}`
    } else {
      return `https://pages${getEnvironmentSuffix()}.rasa.io`
    }
  }
  private buttonClassNames = (enabled: boolean): string => {
    return enabled ? 'save-button save-button-enabled clickable-item' :
                     'save-button save-button-disabled'
  }
  private selectItem = (item: MenuSection) => {
    this.setState({
      selected: item,
    })
  }
  private getEditor = () => {
    if (this.state.selected) {
      switch (this.state.selected.value) {
        case MenuItems.headerAndLogo:
          return this.getHeaderSettings()
        case MenuItems.cta:
          return <SignupSettings data={this.props.data}
                    name={MenuItems.cta}
                    onChange={(field: string, value: string) => this.propertyChanged(field, value)}/>
        case MenuItems.privacy:
          return <PrivacySettings data={this.props.data}
                                 name={MenuItems.privacy}
                                 onChange={(field: string, value: string) => this.propertyChanged(field, value)}/>
      }
    } else {
      return this.getHeaderSettings()
    }
    return <div>No Editor Found</div>
  }
  private getHeaderSettings = () => {
    return <HeaderSettings data={this.props.data}
    headerTextAttribute={SignupLayoutAttributes.HeaderText}
    imageUrlAttribute={SignupLayoutAttributes.HeaderImageUrl}
    showHeaderAttribute={SignupLayoutAttributes.ShowHeader}
    onChange={(field: string, value: string) => this.propertyChanged(field, value)}/>
  }
}

export const WidgetsSignup = GenericRedux.createConnect(WidgetsSignupComponent, REDUX_STORE_HOME)
injectReducer(
  REDUX_STORE_HOME,
  Redux.combineReducers({
    data: GenericRedux.createGenericReducer(REDUX_STORE_HOME, {}),
  }),
)
